import { Box, BoxProps, styled } from "@mui/material";

const LayoutContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  overflowY: "auto",
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(6), // Leaving more space at the bottom
  "@media print": {
    padding: 0
  }
}));

export default LayoutContainer;
