import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Alert, Button } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import EmptyBoxedMessage from "../components/atoms/EmptyBoxedMessage";
import BusinessEditor from "../components/organisms/BusinessEditor";
import TitledPageTemplate from "../components/templates/TitledPageTemplate";
import useBusiness from "../hooks/useBusiness";
import BusinessController from "../repositories/BusinessController";
import { IBusiness } from "../types/IBusiness";
import IBusinessCreateRequestOptions from "../types/IBusinessCreateRequestOptions";

export default function BusinessCreationScreen() {
  const { businessId } = useParams();
  const navigate = useNavigate();
  const { isLoading, business, reload } = useBusiness();
  const [newBusiness, setNewBusiness] = React.useState<IBusiness | null>(null);
  const [error, setError] = React.useState<string>("");

  React.useEffect(() => {
    reload(businessId);
  }, [businessId, reload]);

  const handleSaveBusiness = React.useCallback(async () => {
    if (newBusiness && newBusiness.company) {
      const requestOptions: IBusinessCreateRequestOptions = {
        logo: newBusiness.logo,
        abbreviation: newBusiness.abbreviation,
        currency: newBusiness.currency,
        company: newBusiness.company,
        businessId: businessId,
        template: newBusiness.template
      };

      const result = await BusinessController.create(
        JSON.stringify(requestOptions)
      );

      if (result && result.status === "success" && result.data) {
        setError("");
        return navigate(`/businesses/${result.data.uuid}`, { replace: true });
      }

      if (result && result.message) {
        setError(result.message);
      }
    }
  }, [newBusiness, businessId]);

  return (
    <TitledPageTemplate
      containerId="rda-screen-business-editor"
      title={`${businessId ? "Edit" : "Create"} Business`}
      isLoading={isLoading}
      isLoadingMessage="Loading business..."
      onClickBack={() => navigate(-1)}
      endHeaderAction={
        <Button
          startIcon={<EditRoundedIcon />}
          aria-label="Edit"
          onClick={() => handleSaveBusiness()}
          variant="contained"
          color="secondary"
        >
          Save
        </Button>
      }
    >
      {businessId && business && (
        <Alert severity="warning" variant="filled" sx={{ mb: 2 }}>
          The changes you made here will not affect previously created invoices
          and drafts. All information are locked in when an invoice or draft is
          created.
        </Alert>
      )}
      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
      {!isLoading && business && (
        <BusinessEditor business={business} setBusiness={setNewBusiness} />
      )}
      {businessId && !isLoading && !business && (
        <EmptyBoxedMessage
          title="No Business Found"
          message="You can start by creating a new business."
          action={
            <Button
              startIcon={<ArrowBackIosIcon />}
              aria-label="Add a new business"
              onClick={() => navigate("/businesses")}
              variant="contained"
              color="secondary"
              sx={{ whiteSpace: "nowrap" }}
            >
              Back
            </Button>
          }
        />
      )}
    </TitledPageTemplate>
  );
}
