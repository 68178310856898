import { Box, SxProps, Theme } from "@mui/material";
import firebase from "firebase/compat/app";
import React from "react";
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError
} from "react-router-dom";
import PaperError from "../components/PaperError";
import LayoutTemplate from "../components/templates/LayoutTemplate";
import useBusinesses from "../hooks/useBusinesses";
import { signIn, signOut } from "../redux/AuthSlice";
import { unload } from "../redux/BusinessSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { toggle } from "../redux/ThemeSlice";

export default function ErrorPageScreen() {
  const error = useRouteError();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const { isLoading, businesses, reload, selectBusiness } = useBusinesses();

  const handleLogout = React.useCallback(async () => {
    await firebase.auth().signOut();
    dispatch(signOut());
    dispatch(unload());
    return navigate("/signin");
  }, [dispatch, signOut, unload, navigate]);

  const handleLogin = React.useCallback(async () => {
    return navigate("/signin");
  }, [navigate]);

  const handleToggleMode = React.useCallback(() => {
    dispatch(toggle());
  }, [dispatch, toggle]);

  // Check if user is logged in
  React.useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((firebaseUser) => {
        if (firebaseUser && !user) {
          dispatch(signIn(firebaseUser));
        }
      });

    // Make sure we un-register Firebase observers when the component unmounts.
    return () => unregisterAuthObserver();
  }, [dispatch, signIn]);

  return (
    <LayoutTemplate
      appBar={true}
      themeMode="dark"
      user={user}
      selectedBusiness={selectedBusiness}
      isLoading={isLoading}
      businesses={businesses}
      selectBusiness={selectBusiness}
      reloadBusinesses={reload}
      onLogin={handleLogin}
      onLogout={handleLogout}
      onToggleMode={handleToggleMode}
    >
      <Box id="error-page" sx={containerSx}>
        {isRouteErrorResponse(error) ? (
          <PaperError
            title={`Oops! ${error.status}`}
            subtitle="Sorry, an unexpected error has occurred:"
            bodyTitle={error.statusText}
            bodyContent={error.data?.message}
          />
        ) : error instanceof Error ? (
          <PaperError
            title="Oops! Error"
            subtitle="Sorry, an unexpected error has occurred:"
            bodyContent={error.message}
          />
        ) : (
          <PaperError
            title="Oops! Error"
            subtitle="Sorry, an unexpected error has occurred:"
            bodyContent="The error is unclear."
          />
        )}
      </Box>
    </LayoutTemplate>
  );
}

const containerSx: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "20px",
  overflowY: "scroll"
};
