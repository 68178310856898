import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import {
  Box,
  BoxProps,
  Button,
  Link,
  PaletteMode,
  Paper,
  SxProps,
  Theme,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  TypographyProps,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Image from "../components/atoms/Image";
import PricingItem from "../components/atoms/PricingItem";
import TypingTypography from "../components/atoms/TypingTypography";
import GradientBackground from "../layouts/GradientBackground";
import { useAppSelector } from "../redux/hooks";
import ProductController from "../repositories/ProductController";
import rdaDarkTheme from "../themes/rdaDarkTheme";
import rdaLightTheme from "../themes/rdaLightTheme";

export default function LandingScreen() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargerScreens = useMediaQuery(theme.breakpoints.up("md"));
  const [previewMode, setPreviewMode] = React.useState<PaletteMode>("light");
  const user = useAppSelector((state) => state.auth.user);
  const [products, setProducts] = React.useState<IProduct[]>([]);

  React.useEffect(() => {
    ProductController.list()
      .then((result) => {
        if (result.status === "success") {
          setProducts(result.data);
        }
      })
      .catch((e) => console.error(e));
  }, []);

  return (
    <ContainerBox id="rda-screen-landing">
      <ThemeProvider theme={rdaLightTheme}>
        <GradientBackground
          className="light"
          sx={{ justifyContent: "center", flexDirection: "column" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: 3,
              minHeight: "80vh"
            }}
          >
            <TypingTypography
              variant="h1"
              sx={(theme) => ({
                fontSize: "3rem",
                color: theme.palette.text.primary,
                [theme.breakpoints.down("sm")]: {
                  fontSize: "1.8rem"
                }
              })}
              timing={200}
            >
              Introducing RedeuBooks
            </TypingTypography>
            <Slogan sx={{ p: 1 }}>
              Our new online invoicing system designed specifically for small
              businesses.
            </Slogan>
            {user ? (
              <Button
                onClick={() => navigate("dashboard")}
                variant="contained"
                color="primary"
                sx={{ mt: 2, mb: 1 }}
              >
                My Dashboard
              </Button>
            ) : (
              <Button
                onClick={() => navigate("signin")}
                variant="contained"
                color="primary"
                sx={{ mt: 2, mb: 1 }}
              >
                Create Free Account
              </Button>
            )}
            <Button variant="text">See Our Pricing</Button>
          </Box>
        </GradientBackground>
        <Box
          sx={{
            display: "flex",
            gap: 3,
            flexDirection: isLargerScreens ? "row" : "column",
            pt: 3,
            pb: 4
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              justifyContent: "center",
              gap: 3,
              p: 3
            }}
          >
            <Text sx={{ fontSize: "1.4rem" }}>
              &quot;For a company of one, at any size, simple rules, simple
              processes, and simple solutions typically win.&quot;
            </Text>
            <Text sx={{ fontSize: "1rem", fontStyle: "italic" }}>
              Company of One, Paul Jarvis
            </Text>
            <Text sx={{ mt: 2 }}>
              Inspired by the principles outlined in Paul Jarvis&apos; book
              &quot;Company of One&quot;, we believe that simplicity is key for
              any business, especially for small companies.
            </Text>
            <Text>
              RedeuBooks has a clean and simple interface that requires minimal
              setup, allowing you to create invoices quickly and efficiently.
            </Text>
          </Box>
          <Box
            sx={{
              flex: 1,
              p: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Paper sx={{ overflow: "hidden", mb: 1 }}>
              {previewMode === "light" ? (
                <Image
                  src={require("../images/redeubooks-create-invoice-screenshot-light.webp")}
                />
              ) : (
                <Image
                  src={require("../images/redeubooks-create-invoice-screenshot-dark.webp")}
                />
              )}
            </Paper>
            <ToggleButtonGroup
              size="small"
              exclusive
              value={previewMode}
              onChange={() =>
                setPreviewMode((p) => (p === "light" ? "dark" : "light"))
              }
            >
              <ToggleButton value="light">
                <LightModeIcon />
                <Typography component="span" sx={{ ml: 1 }}>
                  Light Mode
                </Typography>
              </ToggleButton>
              <ToggleButton value="dark">
                <DarkModeIcon />
                <Typography component="span" sx={{ ml: 1 }}>
                  Dark Mode
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        <GradientBackground
          className="dark"
          sx={{
            alignItems: "center",
            flexDirection: "column",
            gap: 3,
            p: isLargerScreens ? 10 : 3,
            pt: 10,
            pb: 10
          }}
        >
          <ThemeProvider theme={rdaDarkTheme}>
            <Header>Simple Invoice</Header>
            <Box sx={{ maxWidth: "1000px" }}>
              <Text>
                With our user-friendly platform, you can easily set up your
                business profile and start generating professional invoices in
                just a few clicks. Say goodbye to complicated invoicing systems
                that require hours of training and setup - our streamlined
                solution is perfect for busy entrepreneurs who value their time
                and want to focus on making their businesses better.
              </Text>
            </Box>
            <Box sx={{ maxWidth: "1200px" }}>
              <Text sx={{ fontSize: "1rem" }}>
                Whether you&apos;re a freelancer, consultant, or small business
                owner, our online invoicing system is designed to meet your
                needs. With simple rules, simple processes, and simple
                solutions, you can spend less time on administrative tasks and
                more time on what really matters - running your business.
              </Text>
            </Box>
          </ThemeProvider>
        </GradientBackground>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
            p: isLargerScreens ? 10 : 3,
            pt: 10,
            pb: 10
          }}
        >
          <Box
            sx={{
              maxWidth: "1000px",
              display: "flex",
              flexDirection: "column",
              gap: 3
            }}
          >
            <Header>RedeuBooks Is Not Accounting Software</Header>
            <Text>
              If you are in need of a comprehensive Accounting solution,
              advanced Accounting features, or complex journaling capabilities,
              you may want to consider exploring other software options.
              RedeuBooks is designed to be a straightforward invoicing system,
              focusing on simplicity. While there may be added features in the
              future, our goal is to remain compact and profitable.
            </Text>
          </Box>
          <Box
            sx={{
              maxWidth: "1000px",
              display: "flex",
              flexDirection: "column",
              gap: 3,
              mt: 6
            }}
          >
            <Header>Developed by a Small Business for Small Businesses</Header>
            <Text>
              RedeuBooks was created by{" "}
              <Link href="https://redooor.com" target="_blank" rel="noopener">
                Redooor LLP
              </Link>
              , a small company based in Singapore. The system was originally
              developed by our co-founder, Andrews, after the previous system he
              was using stopped supporting Singapore. It was initially used as
              our internal invoicing system, but is now available to the public.
            </Text>
          </Box>
        </Box>
        <GradientBackground
          className="light"
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            p: 10,
            alignItems: "center"
          }}
        >
          <Box
            id="pricing"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 3,
              mt: 6
            }}
          >
            <Box>
              <Header>Pricing</Header>
              <Header sx={{ fontSize: "1.4rem" }}>
                Where Simplicity Meets Affordability
              </Header>
            </Box>
            <Box sx={{ display: "flex", gap: 3 }}>
              {products.map((p) => (
                <PricingItem
                  key={`pricing-${p.sku}`}
                  title={p.title}
                  description={p.description}
                  currency={p.currency}
                  price={p.price}
                  billingMethod={`/ ${p.quantity} ${p.unit}`}
                  priceDescription={p.priceDescription}
                  features={p.features}
                />
              ))}
            </Box>
            <Text sx={{ fontSize: "1rem" }}>
              *100 free accounts are opened for registration everyday. This
              limit will be refreshed everyday at 0:00 hr SGT. Free accounts are
              available for limited time only.
            </Text>
          </Box>
          <Paper
            sx={{ p: isLargerScreens ? 10 : 3, textAlign: "center", mt: 6 }}
          >
            <Text>
              Join the thousands of small businesses who have already made the
              switch to our online invoicing system and experience the
              difference simplicity can make. Sign up today and start creating
              invoices in minutes - because in the world of business, simple is
              always better.
            </Text>
            <Button
              onClick={() => navigate("signin")}
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 1 }}
            >
              Start Now - It&apos;s Free
            </Button>
            <Text sx={{ fontSize: "0.8rem", fontStyle: "italic" }}>
              No credit card required
            </Text>
          </Paper>
        </GradientBackground>
      </ThemeProvider>
      <GradientBackground sx={FooterStyle}>
        <Box sx={{ flex: 1 }}>
          <Typography>
            &copy; Copyright since 2021, Redooor LLP. All rights reserved.
          </Typography>
        </Box>
        <Link
          sx={(theme) => ({ color: theme.palette.text.primary })}
          href="https://redooor.com/terms-of-service/"
        >
          Terms & Conditions
        </Link>
        <Link
          sx={(theme) => ({ color: theme.palette.text.primary })}
          href="https://redooor.com/privacy-policy/"
        >
          Privacy Policy
        </Link>
      </GradientBackground>
    </ContainerBox>
  );
}

const ContainerBox = styled(Box)<BoxProps>({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#F2F1EB"
});

const Slogan = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "1.6rem",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.2rem"
  }
}));

const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "1.2rem",
  textAlign: "center",
  "& a": {
    fontSize: "1.2rem"
  }
}));

const Header = styled(Text)<TypographyProps>(({ theme }) => ({
  fontSize: "2rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.6rem"
  }
}));

const FooterStyle: SxProps<Theme> = (theme) => ({
  flex: 1,
  display: "flex",
  columnGap: theme.spacing(3),
  rowGap: theme.spacing(1),
  padding: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
});
