import { styled, Typography, TypographyProps } from "@mui/material";
import React from "react";

interface TypingTypographyProps extends TypographyProps {
  children: string;
  timing: number;
}

export default function TypingTypography(props: TypingTypographyProps) {
  const { children, timing } = props;
  const [typingText, setTypingText] = React.useState<string>(
    children?.slice(0, 1) ?? ""
  );
  let counter = 1;

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (counter > children.length) {
        clearInterval(timer);
        return;
      }
      setTypingText(children.slice(0, counter));
      counter++;
    }, timing);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <BlinkingTypography {...props}>{typingText}</BlinkingTypography>;
}

const BlinkingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  borderRight: `.15em solid ${theme.palette.secondary.main}` /* The typwriter cursor */,
  animation: "blink-caret .75s step-end infinite",
  "@keyframes blink-caret": {
    "from, to": { borderColor: "transparent" },
    "50%": { borderColor: theme.palette.secondary.main }
  }
}));
