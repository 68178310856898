import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Theme
} from "@mui/material";
import { IBusiness } from "../../../types/IBusiness";

type BusinessSelectorProps = {
  selectedBusiness?: IBusiness | null;
  businesses: IBusiness[];
  onChangeBusiness: (businessId: string) => void;
  sx?: SxProps<Theme>;
  size?: "small" | "medium";
};

export default function BusinessSelector({
  selectedBusiness,
  businesses,
  onChangeBusiness,
  sx = [],
  size = "small"
}: BusinessSelectorProps) {
  return (
    <FormControl color="secondary" sx={sx} size={size}>
      <InputLabel id="select-business-label" htmlFor="select-business-input">
        Business
      </InputLabel>
      <Select
        id="select-business"
        value={selectedBusiness?.uuid}
        label="Business"
        onChange={(e) => onChangeBusiness(e.target.value)}
        inputProps={{
          id: "select-business-input"
        }}
      >
        {businesses.map((b) => (
          <MenuItem value={b.uuid} key={b.uuid}>
            {b.company.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
