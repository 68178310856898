import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import {
  Box,
  Button,
  ButtonGroup,
  Paper,
  SxProps,
  TableCell,
  TableCellProps,
  TableContainer,
  TableRow,
  TableRowProps,
  TextField,
  Theme,
  Tooltip,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React from "react";
import getAmountInCurrency from "../../../helpers/getAmountInCurrency";
import { IJournal } from "../../../types/IJournal";
import { ITax } from "../../../types/ITax";
import ResponsiveTable from "../../atoms/ResponsiveTable";
import IResponsiveTableColumn from "../../atoms/ResponsiveTable/types/IResponsiveTableColumn";
import IResponsiveTableHeader from "../../atoms/ResponsiveTable/types/IResponsiveTableHeader";
import IResponsiveTableRow from "../../atoms/ResponsiveTable/types/IResponsiveTableRow";

type JournalsFormProps = {
  journals: IJournal[];
  currency?: string;
  tax?: ITax | null;
  onAddJournal: (i: IJournal[]) => void;
  onToggleForm: (o: boolean) => void;
  sx?: SxProps<Theme>;
};

export default function JournalsForm({
  journals,
  currency,
  tax = null,
  onAddJournal,
  onToggleForm,
  sx = []
}: JournalsFormProps) {
  const theme = useTheme();
  const isLargerScreens = useMediaQuery(theme.breakpoints.up("sm"));

  const [title, setTitle] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [quantity, setQuantity] = React.useState<string>("");
  const [unit_price, setUnitPrice] = React.useState<string>("");
  const [showForm, setShowForm] = React.useState<boolean>(false);

  const JOURNAL_FORM_HEADERS: IResponsiveTableHeader[] = React.useMemo(
    () => [
      {
        index: "journal-form-header-actions",
        label: "",
        lgSx: { width: "50px !important" },
        smSx: { display: "none" }
      },
      {
        index: "journal-form-header-item",
        label: "Item"
      },
      {
        index: "journal-form-header-description",
        label: "Description"
      },
      {
        index: "journal-form-header-quantity",
        label: (
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              columnGap: 0.5
            }}
          >
            Quantity
            <Tooltip title="Can support up to 2 decimal places" arrow>
              <HelpRoundedIcon
                fontSize="small"
                color="secondary"
                sx={{ verticalAlign: "text-bottom", ml: 0.5 }}
              />
            </Tooltip>
          </Box>
        ),
        lgSx: { width: "200px !important" }
      },
      {
        index: "journal-form-header-unit-price",
        label: (
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              columnGap: 0.5
            }}
          >
            Unit Price
            <Tooltip title="Only supports up to 2 decimal places" arrow>
              <HelpRoundedIcon
                fontSize="small"
                color="secondary"
                sx={{ verticalAlign: "text-bottom", ml: 0.5 }}
              />
            </Tooltip>
          </Box>
        ),
        lgSx: { width: "200px !important" }
      },
      {
        index: "journal-form-header-subtotal",
        label: "Subtotal"
      }
    ],
    []
  );

  const addItem = React.useCallback(() => {
    if (isNaN(Number(quantity)) || isNaN(Number(unit_price)) || title === "") {
      setShowForm(false);
      onToggleForm(false);
      return null; // Do nothing until user correct the mistake
    }

    const roundedQuantity = parseFloat(quantity).toFixed(2);
    const roundedUnitPrice = parseFloat(unit_price).toFixed(2);
    const amount = Number(roundedQuantity) * Number(roundedUnitPrice);

    const newItems = [...journals];
    newItems.push({
      uuid: "",
      parent: "",
      amount: amount.toFixed(2).toString(),
      currency: currency ? currency : "",
      credit: "",
      debit: "",
      datetime: "",
      created_at: "",
      updated_at: "",
      document: {
        name: title,
        description: description,
        quantity: roundedQuantity,
        unit_price: roundedUnitPrice
      }
    });

    onAddJournal(newItems);
    // Reset to blank
    setTitle("");
    setDescription("");
    setQuantity("");
    setUnitPrice("");
    setShowForm(false);
    onToggleForm(false);
  }, [journals, onAddJournal, quantity, unit_price, title, description]);

  const removeItem = React.useCallback(
    (index: number) => {
      const _rows = [...journals];
      _rows.splice(index, 1);
      onAddJournal(_rows);
    },
    [journals, onAddJournal]
  );

  const subAmountBeforeTax = React.useMemo(() => {
    return journals.reduce(
      (accumulator, currentValue) => accumulator + Number(currentValue.amount),
      0
    );
  }, [journals]);

  const taxAmount = React.useMemo(() => {
    if (tax && subAmountBeforeTax) {
      return subAmountBeforeTax * parseFloat(tax.rate);
    }
    return 0;
  }, [tax, subAmountBeforeTax]);

  const grandAmount = React.useMemo(() => {
    return subAmountBeforeTax + taxAmount;
  }, [subAmountBeforeTax, taxAmount]);

  const handleEditJournal = React.useCallback(
    (index: number, item: IJournal) => {
      setDescription(item.document.description);
      setTitle(item.document.name);
      setQuantity(item.document.quantity);
      setUnitPrice(item.document.unit_price);
      setShowForm(true);
      removeItem(index);
    },
    [removeItem]
  );

  const journalRows: IResponsiveTableRow[] = React.useMemo(() => {
    const _rows: IResponsiveTableRow[] = [];

    journals?.length > 0 &&
      journals.map((journal, i) => {
        const _cols: IResponsiveTableColumn[] = [
          {
            headerIdx: "journal-form-header-actions",
            uuid: `journal-form-header-actions-${i}`,
            label: (
              <ButtonGroup
                size="small"
                variant="contained"
                sx={{ mb: isLargerScreens ? 0 : 2 }}
              >
                <Button
                  onClick={() => removeItem(i)}
                  color="secondary"
                  size="small"
                >
                  <DeleteRoundedIcon />
                </Button>
                <Button
                  onClick={() => handleEditJournal(i, journal)}
                  color="primary"
                  size="small"
                >
                  <EditRoundedIcon />
                </Button>
              </ButtonGroup>
            )
          },
          {
            headerIdx: "journal-form-header-item",
            uuid: `journal-form-header-item-${i}`,
            label: journal.document.name
          },
          {
            headerIdx: "journal-form-header-description",
            uuid: `journal-form-header-description-${i}`,
            label: journal.document.description
          },
          {
            headerIdx: "journal-form-header-quantity",
            uuid: `journal-form-header-quantity-${i}`,
            label: journal.document.quantity
          },
          {
            headerIdx: "journal-form-header-unit-price",
            uuid: `journal-form-header-unit-price-${i}`,
            label: journal.document.unit_price
          },
          {
            headerIdx: "journal-form-header-subtotal",
            uuid: `journal-form-header-subtotal-${i}`,
            label: journal.amount
          }
        ];

        _rows.push({
          uuid: `payment-${i}`,
          columns: _cols
        });
      });
    return _rows;
  }, [journals]);

  return (
    <TableContainer component={Paper} variant="outlined" sx={sx}>
      <ResponsiveTable headers={JOURNAL_FORM_HEADERS} rows={journalRows}>
        {isLargerScreens && journals && journals.length > 0 && (
          <>
            {tax && (
              <>
                <TableRow
                  sx={{ backgroundColor: theme.palette.action.selected }}
                >
                  <TableCell variant="head" colSpan={5} align="right">
                    Sub Total
                  </TableCell>
                  <TableCell variant="head">
                    {currency
                      ? getAmountInCurrency(currency, subAmountBeforeTax)
                      : subAmountBeforeTax.toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ backgroundColor: theme.palette.action.selected }}
                >
                  <TableCell variant="head" colSpan={5} align="right">
                    Total Tax ({tax.name})
                  </TableCell>
                  <TableCell variant="head">
                    {currency
                      ? getAmountInCurrency(currency, taxAmount)
                      : taxAmount.toFixed(2)}
                  </TableCell>
                </TableRow>
              </>
            )}
            <TableRow sx={{ backgroundColor: theme.palette.action.selected }}>
              <TableCell variant="head" colSpan={5} align="right">
                Grand Total
              </TableCell>
              <TableCell variant="head">
                {currency
                  ? getAmountInCurrency(currency, grandAmount)
                  : grandAmount.toFixed(2)}
              </TableCell>
            </TableRow>
          </>
        )}
        {!isLargerScreens && journals && journals.length > 0 && (
          <GrandTotalMobileRow>
            {tax && (
              <>
                <GrandTotalMobileCell>
                  <Box component="span" className="header">
                    Sub Total
                  </Box>
                  <Box component="span" className="label">
                    {currency
                      ? getAmountInCurrency(currency, subAmountBeforeTax)
                      : subAmountBeforeTax.toFixed(2)}
                  </Box>
                </GrandTotalMobileCell>
                <GrandTotalMobileCell>
                  <Box component="span" className="header">
                    Total Tax ({tax.name})
                  </Box>
                  <Box component="span" className="label">
                    {currency
                      ? getAmountInCurrency(currency, taxAmount)
                      : taxAmount.toFixed(2)}
                  </Box>
                </GrandTotalMobileCell>
              </>
            )}
            <GrandTotalMobileCell>
              <Box component="span" className="header">
                Grand Total
              </Box>
              <Box component="span" className="label">
                {currency
                  ? getAmountInCurrency(currency, grandAmount)
                  : grandAmount.toFixed(2)}
              </Box>
            </GrandTotalMobileCell>
          </GrandTotalMobileRow>
        )}
      </ResponsiveTable>
      {showForm ? (
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: isLargerScreens ? "row" : "column",
            gap: 2,
            p: 3,
            backgroundColor: theme.palette.action.hover
          })}
        >
          <TextField
            fullWidth={true}
            size="small"
            autoFocus
            id="inputTitle"
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            fullWidth={true}
            size="small"
            id="inputDescription"
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            fullWidth={true}
            size="small"
            id="inputQuantity"
            label="Quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            error={isNaN(Number(quantity))}
            helperText={isNaN(Number(quantity)) && "Numbers only."}
          />
          <TextField
            fullWidth={true}
            size="small"
            id="inputUnitPrice"
            label="Unit Price"
            value={unit_price}
            onChange={(e) => setUnitPrice(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addItem();
              }
            }}
            error={isNaN(Number(unit_price))}
            helperText={isNaN(Number(unit_price)) && "Numbers only."}
          />
          <ButtonGroup
            variant="contained"
            aria-label="Journal Form Button Group"
            size="small"
            fullWidth
            sx={{ maxHeight: theme.spacing(5) }}
          >
            <Button startIcon={<AddRoundedIcon />} onClick={() => addItem()}>
              Add
            </Button>
            <Button
              onClick={() => {
                setShowForm(false);
                onToggleForm(false);
              }}
              color="secondary"
            >
              <CloseRoundedIcon />
            </Button>
          </ButtonGroup>
        </Box>
      ) : (
        <Box
          sx={(theme) => ({
            p: 3,
            backgroundColor: theme.palette.action.hover
          })}
        >
          <Button
            variant="text"
            endIcon={<AddRoundedIcon />}
            onClick={() => {
              setShowForm(true);
              onToggleForm(true);
            }}
            color="secondary"
            fullWidth={true}
            size="small"
          >
            Add new {journals.length > 0 ? "line" : "item"}
          </Button>
        </Box>
      )}
    </TableContainer>
  );
}

const GrandTotalMobileRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.action.selected
}));

const GrandTotalMobileCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  border: 0,
  padding: 0,
  display: "flex",
  justifyContent: "space-between",
  "& .header": {
    color: theme.palette.grey[500]
  },
  "& .label": {
    flex: 1,
    textAlign: "right"
  }
}));
