import { Autocomplete, SxProps, TextField, Theme } from "@mui/material";
import React from "react";
import { ITax } from "../../../types/ITax";

type TaxSelectorProps = {
  taxes: ITax[];
  loading: boolean;
  selectedTax: ITax | null;
  onChange: (tax: ITax | null) => void;
  sx?: SxProps<Theme>;
  size?: "small" | "medium";
  fullWidth?: boolean;
  disabled?: boolean;
  helperText?: string;
};

export default function TaxSelector({
  taxes,
  loading = true,
  selectedTax,
  onChange,
  sx = [],
  size = "small",
  fullWidth = false,
  disabled = false,
  helperText
}: TaxSelectorProps) {
  return (
    <Autocomplete
      disablePortal
      id="rdr-tax-selector-form"
      value={selectedTax}
      onChange={(e, value) => onChange(value)}
      options={taxes}
      getOptionLabel={(option) => option.name}
      fullWidth={fullWidth}
      size={size}
      sx={sx}
      disabled={disabled}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Sales Tax"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            )
          }}
          helperText={helperText}
        />
      )}
    />
  );
}
