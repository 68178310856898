import { IPaginatedResponse } from "../types/IPaginatedResponse";
import HttpController from "./HttpController";

class ProductController {
  /**
   * Represents a Business.
   * @constructor
   */
  constructor() {
    return this;
  }

  /**
   * Returns a list of public products
   */
  static async list(): Promise<IPaginatedResponse<IProduct>> {
    const url = "/open/api/products";

    try {
      const result = await HttpController.getPublic(url, false);
      return result;
    } catch (e) {
      console.error(e);
    }

    return {
      status: "error",
      data: []
    };
  }
}

export default ProductController;
