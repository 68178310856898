import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import DescriptionIcon from "@mui/icons-material/Description";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import PrintIcon from "@mui/icons-material/Print";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IInvoiceSimple } from "../../../types/IInvoiceSimple";

type InvoiceListItemMenuProps = {
  anchorEl: null | HTMLElement;
  selectedMenu: IInvoiceSimple;
  onClose: () => void;
};

export default function InvoiceListItemMenu({
  anchorEl,
  selectedMenu,
  onClose
}: InvoiceListItemMenuProps) {
  const navigate = useNavigate();

  // Handle menu click
  const open = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": "invoice-action-button"
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
    >
      <MenuList dense>
        <MenuItem
          onClick={() => {
            onClose(); // Close menu
            return navigate(`/invoices/${selectedMenu.uuid}/view`);
          }}
        >
          <ListItemIcon>
            <DescriptionIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="View Invoice" secondary="For Details" />
        </MenuItem>
        {["OUTSTANDING", "PARTIAL"].includes(selectedMenu.invoice.status) && (
          <MenuItem
            onClick={() => {
              onClose(); // Close menu
              return navigate(`/invoices/${selectedMenu.uuid}/payment`);
            }}
          >
            <ListItemIcon>
              <AddRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary="Add Payment"
              secondary={`to #${selectedMenu.invoice.number}`}
            />
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            onClose(); // Close menu
            navigate(`/invoices/${selectedMenu.uuid}/print`);
          }}
        >
          <ListItemIcon>
            <PrintIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Print Preview" secondary="For Print" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClose(); // Close menu
            return window.open(
              `${window.location.origin}/invoices/${selectedMenu.public_link}/print/${selectedMenu.invoice.number}`
            );
          }}
        >
          <ListItemIcon>
            <OpenInNewRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Public View" secondary="For Public Print" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigator.clipboard.writeText(
              `${window.location.origin}/invoices/${selectedMenu.public_link}/print/${selectedMenu.invoice.number}`
            );
            onClose(); // Close menu
          }}
        >
          <ListItemIcon>
            <CopyAllRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Copy Public Link" secondary="For Client" />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            onClose(); // Close menu
            return navigate(`/invoices/create/${selectedMenu.uuid}`);
          }}
        >
          <ListItemIcon>
            <ContentCopyRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Duplicate" secondary="Create New Invoice" />
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
