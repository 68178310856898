import { Alert, Box, Button } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import PaymentForm from "../components/molecules/PaymentForm";
import InvoicePageTemplate from "../components/templates/InvoicePageTemplate";
import getAmountInCurrency from "../helpers/getAmountInCurrency";
import useSingleInvoice from "../hooks/useSingleInvoice";
import { useAppSelector } from "../redux/hooks";
import PaymentController from "../repositories/PaymentController";
import {
  IPaymentRequestOptions,
  IPaymentRequestOptionsItem
} from "../types/IPaymentRequestOptions";

export default function AddPaymentScreen() {
  const { invoiceId } = useParams();
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const { isLoading, invoice, reload } = useSingleInvoice();
  const [payments, setPayments] = React.useState<IPaymentRequestOptionsItem[]>(
    []
  );
  const [paymentFormDirty, setPaymentFormDirty] = React.useState<boolean>(true); // Since we opened form by default
  const [paymentError, setPaymentError] = React.useState<string>("");
  const [saveError, setSaveError] = React.useState<string>("");
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (invoiceId && selectedBusiness) {
      reload(invoiceId); // Get invoice
    }
  }, [invoiceId, reload, selectedBusiness]);

  // Check total payment vs invoiced total
  React.useEffect(() => {
    if (invoice) {
      const totalAddedPayment = payments.reduce(
        (acc, cur) => acc + parseFloat(cur.amount),
        0
      );
      const totalPayment = totalAddedPayment + parseFloat(invoice.total_paid);

      if (totalPayment > parseFloat(invoice.grand_total)) {
        setPaymentError(
          `Payment total ${getAmountInCurrency(
            invoice.invoice.currency,
            totalPayment
          )} is more than the invoiced amount ${getAmountInCurrency(
            invoice.invoice.currency,
            invoice.grand_total
          )}.`
        );
      } else {
        setPaymentError("");
      }
    }
  }, [invoice, payments]);

  /**
   * Save payment to server
   */
  const save = React.useCallback(async () => {
    setIsSaving(true);
    if (!invoice) {
      setSaveError("No invoice specified.");
      return;
    }

    if (!selectedBusiness || !selectedBusiness.uuid) {
      setSaveError("No selected business.");
      return;
    }

    const requestOptions: IPaymentRequestOptions = {
      invoice_id: invoice.uuid,
      customer_id: invoice.customer_id,
      payments: payments
    };

    const paid = await PaymentController.add(
      selectedBusiness.uuid,
      JSON.stringify(requestOptions)
    );

    setIsSaving(true);

    if (paid?.status === "success") {
      setSaveError("");
      navigate(`/invoices/${invoice.uuid}/view`, { replace: true });
    } else {
      setSaveError("Server unable to save the payment.");
    }
  }, [invoice, payments, selectedBusiness]);

  return (
    <InvoicePageTemplate
      containerId="rda-screen-invoice-viewer"
      invoice={invoice}
      title="Add Payment"
      onClickBack={() => navigate(-1)}
      isLoading={isLoading}
      isLoadingMessage="Loading invoice..."
      isEmpty={!isLoading && !invoice}
      isEmptyTitle="No invoice found"
      isEmptyMessage="Go back to invoices and try again."
      isEmptyAction={
        <Button
          aria-label="Add a new payment"
          onClick={() => navigate("/invoices")}
          variant="contained"
          color="secondary"
          sx={{ whiteSpace: "nowrap" }}
        >
          Back to Invoices
        </Button>
      }
      startChildren={
        !isLoading &&
        invoice && (
          <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
            {selectedBusiness && (
              <PaymentForm
                invoice={invoice}
                payments={payments}
                businessCurrency={
                  selectedBusiness.currency ? selectedBusiness.currency : "SGD"
                }
                onAddPayment={setPayments}
                onToggleForm={setPaymentFormDirty}
                defaultShowForm={true}
                isSaving={isSaving}
                disabled={
                  isSaving ||
                  paymentFormDirty ||
                  payments?.length === 0 ||
                  !!paymentError
                }
                onCancel={() => navigate(-1)}
                onSubmit={save}
              />
            )}
            {paymentFormDirty && (
              <Alert variant="filled" severity="error">
                Payment form is opened. Have you added the payment?
              </Alert>
            )}
            {saveError && (
              <Alert variant="filled" severity="error">
                {saveError}
              </Alert>
            )}
            {paymentError && (
              <Alert variant="filled" severity="error">
                {paymentError}
              </Alert>
            )}
          </Box>
        )
      }
    />
  );
}
