import { Box, BoxProps, PaletteMode, styled } from "@mui/material";
import firebase from "firebase/compat/app";
import * as React from "react";
import { IBusiness } from "../../../types/IBusiness";
import AppNavBar from "../../organisms/AppNavBar";

type LayoutTemplateProps = {
  themeMode?: PaletteMode;
  appBar?: boolean;
  user?: firebase.User | null;
  selectedBusiness?: IBusiness | null;
  isLoading?: boolean;
  businesses?: IBusiness[];
  selectBusiness?: (b: string) => void;
  reloadBusinesses?: (limit: number, page: number) => void;
  onLogin?: () => void;
  onLogout?: () => void;
  onToggleMode?: () => void;
  children: React.ReactNode;
};

export default function LayoutTemplate({
  themeMode = "light",
  appBar = false,
  user = null,
  selectedBusiness = null,
  isLoading = true,
  businesses = [],
  selectBusiness,
  reloadBusinesses,
  onLogin,
  onLogout,
  onToggleMode,
  children
}: LayoutTemplateProps) {
  return (
    <ContainerBox
      sx={{ flexDirection: "column" }}
      className={
        themeMode === "dark" ? "rda-dark-background" : "rda-light-background"
      }
    >
      {appBar && (
        <AppNavBar
          user={user}
          selectedBusiness={selectedBusiness}
          onLogin={onLogin}
          onLogout={onLogout}
          onToggleMode={onToggleMode}
          isLoading={isLoading}
          businesses={businesses}
          selectBusiness={selectBusiness}
          reloadBusinesses={reloadBusinesses}
        />
      )}
      {children}
    </ContainerBox>
  );
}

const ContainerBox = styled(Box)<BoxProps>({
  display: "flex",
  flex: 1,
  "&.rda-light-background": {
    backgroundColor: "#F2F1EB"
  },
  "&.rda-dark-background": {
    backgroundColor: "#212124"
  }
});
