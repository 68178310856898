import {
  Box,
  CircularProgress,
  SxProps,
  Theme,
  Typography
} from "@mui/material";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import PaperForm from "../components/PaperForm";
import config from "../config/app";
import GradientBackground from "../layouts/GradientBackground";
import { loaded, signIn } from "../redux/AuthSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import "./SignInScreen.css"; /* Overwrite FirebaseUI styles */

export default function SignInScreen() {
  const navigate = useNavigate();
  const isLoading = useAppSelector((state) => state.auth.isLoading);
  const dispatch = useAppDispatch();

  // Listen to the Firebase Auth state and set the local state.
  React.useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (isLoading) {
          // Firebase is ready, display the screen
          dispatch(loaded());
        }

        if (user) {
          dispatch(signIn(user));
          return navigate("/dashboard");
        }

        handleFirebaseUI();
      });

    // Make sure we un-register Firebase observers when the component unmounts.
    return () => unregisterAuthObserver();
  }, [dispatch, isLoading, loaded, signIn]);

  const handleFirebaseUI = React.useCallback(() => {
    // FirebaseUI config.
    const uiConfig = {
      callbacks: {
        uiShown: function () {
          dispatch(loaded());
        }
      },
      signInSuccessUrl: "/dashboard",
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true,
          disableSignUp: {
            status: true
          }
        }
      ],
      tosUrl: "https://redooor.com/terms-of-service/",
      privacyPolicyUrl: "https://redooor.com/privacy-policy/"
    };

    // Set persistance to current session only
    firebase
      .auth()
      .setPersistence("session")
      .then(() => {
        // Initialize the FirebaseUI Widget using Firebase.
        const ui =
          firebaseui.auth.AuthUI.getInstance() ||
          new firebaseui.auth.AuthUI(firebase.auth());
        // The start method will wait until the DOM is loaded.
        ui.start("#firebaseui-auth-container", uiConfig);
      });
  }, [dispatch, loaded, signIn]);

  return (
    <GradientBackground sx={containerSx}>
      <PaperForm>
        <Box sx={headerContainerSx}>
          <Typography sx={subtitleSx}>{config.app_subtitle}</Typography>
        </Box>
        <div id="firebaseui-auth-container" style={{ width: "100%" }}></div>
        {isLoading && (
          <Box sx={loadingContainerSx}>
            <CircularProgress />
            <Typography variant="body1" sx={{ margin: "10px 0" }}>
              Please hold while we connect you...
            </Typography>
          </Box>
        )}
      </PaperForm>
    </GradientBackground>
  );
}

const containerSx: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "20px",
  overflowY: "scroll"
};

const headerContainerSx: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
  marginBottom: "2rem"
};

const subtitleSx: SxProps<Theme> = {
  fontSize: "16px"
};

const loadingContainerSx: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
};
