import LoadingBox from "../components/atoms/LoadingBox";
import LayoutTemplate from "../components/templates/LayoutTemplate";

export default function LoadingScreen() {
  return (
    <LayoutTemplate appBar={false}>
      <LoadingBox />
    </LayoutTemplate>
  );
}
