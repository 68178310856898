import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  BoxProps,
  Button,
  Drawer,
  IconButton,
  ThemeProvider,
  Toolbar,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import firebase from "firebase/compat/app";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import rdaDarkTheme from "../../../themes/rdaDarkTheme";
import { IBusiness } from "../../../types/IBusiness";
import BusinessSelector from "../../atoms/BusinessSelector";
import Image from "../../atoms/Image";
import ProfileMenu from "../../atoms/ProfileMenu";
import SideBar from "../../atoms/SideBar";

type AppNavBarProps = {
  user: firebase.User | null;
  selectedBusiness: IBusiness | null;
  isLoading?: boolean;
  businesses?: IBusiness[];
  selectBusiness?: (b: string) => void;
  reloadBusinesses?: (limit: number, page: number) => void;
  onLogin?: () => void;
  onLogout?: () => void;
  onToggleMode?: () => void;
};

export default function AppNavBar({
  user,
  selectedBusiness,
  isLoading = true,
  businesses = [],
  selectBusiness = () => null,
  reloadBusinesses,
  onLogin,
  onLogout,
  onToggleMode
}: AppNavBarProps) {
  const theme = useTheme();
  const isLargerScreens = useMediaQuery(theme.breakpoints.up("sm"));

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openSideBar, setOpenSideBar] = React.useState<boolean>(false);

  React.useEffect(() => {
    reloadBusinesses && reloadBusinesses(100, 1); // Load 100 businesses of this user
  }, [user, reloadBusinesses]);

  return (
    <AppBarBox id="rda-app-navbar">
      <AppBar position="static">
        <Toolbar>
          {user && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setOpenSideBar((prev) => !prev)}
            >
              <MenuIcon />
            </IconButton>
          )}
          {(isLargerScreens || !user) && (
            <Box onClick={() => navigate("/")} sx={{ cursor: "pointer" }}>
              <Image
                src={require("../../../images/redeubooks-logo.png")}
                width={160}
                height={30}
                fit="contain"
              />
            </Box>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {pathname === "/" && (
            <ThemeProvider theme={rdaDarkTheme}>
              <Button href="#pricing" variant="contained">
                Pricing
              </Button>
            </ThemeProvider>
          )}
          {!isLoading && businesses?.length > 0 && (
            <ThemeProvider theme={rdaDarkTheme}>
              <BusinessSelector
                size="small"
                selectedBusiness={selectedBusiness}
                businesses={businesses}
                onChangeBusiness={selectBusiness}
                sx={{ maxWidth: isLargerScreens ? "none" : "200px", mr: 2 }}
              />
            </ThemeProvider>
          )}
          {pathname !== "/signin" && (
            <ThemeProvider theme={rdaDarkTheme}>
              <ProfileMenu
                user={user}
                themeMode="dark"
                onLogin={onLogin}
                onLogout={onLogout}
                onToggleMode={onToggleMode}
              />
            </ThemeProvider>
          )}
        </Toolbar>
      </AppBar>
      {user && (
        <Drawer open={openSideBar} onClose={() => setOpenSideBar(false)}>
          <SideBar
            company={selectedBusiness?.company}
            onDismiss={() => setOpenSideBar(false)}
          />
        </Drawer>
      )}
    </AppBarBox>
  );
}

const AppBarBox = styled(Box)<BoxProps>({
  width: "100%",
  "@media print": {
    display: "none"
  }
});
