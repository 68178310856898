import { Box, BoxProps, SxProps, Theme, styled } from "@mui/material";
import * as React from "react";
import "./GradientBackground.css";

type GradientBackgroundProps = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  className?: string;
};

export default function GradientBackground({
  children,
  sx = [],
  className = ""
}: GradientBackgroundProps) {
  return (
    <ContainerBox
      className={[className, "rda-gradient-background"].join(" ")}
      sx={sx}
    >
      {children}
    </ContainerBox>
  );
}

const ContainerBox = styled(Box)<BoxProps>({
  display: "flex",
  flex: 1
  // Background Gradient is defined in css file
});
