import {
  Box,
  BoxProps,
  SxProps,
  Theme,
  ThemeProvider,
  styled
} from "@mui/material";
import React from "react";
import rdaDarkTheme from "../themes/rdaDarkTheme";
import PaperFormFooter from "./PaperFormFooter";
import PaperFormTitle from "./PaperFormTitle";
import Image from "./atoms/Image";

interface PaperFormProps {
  title?: string;
  showLogo?: boolean;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}

export default function PaperForm({
  title,
  children,
  showLogo = true,
  sx
}: PaperFormProps) {
  return (
    <ThemeProvider theme={rdaDarkTheme}>
      <StyledContainer sx={sx}>
        {showLogo && (
          <LogoBox>
            <Image
              src={require("../images/redeubooks-logo.png")}
              width={200}
              height={60}
              fit="contain"
            />
          </LogoBox>
        )}
        <PaperFormTitle title={title} />
        {children}
        <PaperFormFooter />
      </StyledContainer>
    </ThemeProvider>
  );
}

const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  maxWidth: "600px",
  padding: theme.spacing(3),
  color: theme.palette.common.white
}));

const LogoBox = styled(Box)<BoxProps>({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});
