import { Box, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { IInvoice } from "../../../types/IInvoice";
import EmptyBoxedMessage from "../../atoms/EmptyBoxedMessage";
import AuditsView from "../../molecules/AuditsView";
import InvoiceGridView from "../../molecules/InvoiceGridView";
import PaymentsView from "../../molecules/PaymentsView";
import TitledPageTemplate from "../TitledPageTemplate";

type InvoicePageTemplateProps = {
  containerId?: string;
  containerSx?: SxProps<Theme>;
  title: string;
  isLoading?: boolean;
  isLoadingMessage?: string;
  isEmpty?: boolean;
  isEmptyTitle?: string;
  isEmptyMessage?: string;
  isEmptyAction?: React.ReactNode;
  startHeaderAction?: React.ReactNode;
  endHeaderAction?: React.ReactNode;
  startChildren?: React.ReactNode;
  endChildren?: React.ReactNode;
  invoice?: IInvoice | null;
  onClickBack?: () => void;
};

export default function InvoicePageTemplate({
  containerId,
  containerSx = [],
  title,
  isLoading,
  isLoadingMessage = "Loading invoices...",
  isEmpty,
  isEmptyTitle = "No invoice found",
  isEmptyMessage = "You can start by adding an invoice.",
  isEmptyAction,
  startHeaderAction,
  endHeaderAction,
  startChildren,
  endChildren,
  onClickBack,
  invoice
}: InvoicePageTemplateProps) {
  return (
    <TitledPageTemplate
      containerId={containerId}
      containerSx={containerSx}
      title={title}
      startHeaderAction={startHeaderAction}
      endHeaderAction={endHeaderAction}
      onClickBack={onClickBack}
      isLoading={isLoading}
      isLoadingMessage={isLoadingMessage.toUpperCase()}
    >
      {!isLoading && !isEmpty && invoice && (
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
          {startChildren}
          <InvoiceGridView
            isDraft={false}
            billTo={invoice.bill_to}
            invoiceId={invoice.uuid}
            invoiceInfo={invoice.invoice}
            journals={invoice.journals}
            totalPaid={invoice.total_paid}
            subtotal={invoice.subtotal}
            grandTotal={invoice.grand_total}
            totalTax={invoice.total_tax}
            taxes={invoice.taxes}
            payments={invoice.payments}
            additionalNote={invoice.additional_note}
          />
          {invoice?.payments && invoice.payments.length > 0 && (
            <Box id="payment-box">
              <Typography variant="h6">
                Payment{invoice.payments.length > 1 ? "s" : ""}
              </Typography>
              <PaymentsView payments={invoice.payments} />
            </Box>
          )}
          {invoice?.audits && invoice.audits.length > 0 && (
            <Box id="audit-box">
              <Typography variant="h6">Audit Trail</Typography>
              <AuditsView audits={invoice.audits} />
            </Box>
          )}
          {endChildren}
        </Box>
      )}
      {!isLoading && isEmpty && (
        <EmptyBoxedMessage
          title={isEmptyTitle}
          message={isEmptyMessage}
          action={isEmptyAction}
        />
      )}
    </TitledPageTemplate>
  );
}
