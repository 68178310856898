import DoneIcon from "@mui/icons-material/Done";
import {
  Box,
  Paper,
  SxProps,
  Theme,
  ThemeProvider,
  Typography
} from "@mui/material";
import React from "react";
import getAmountInCurrency from "../../../helpers/getAmountInCurrency";
import GradientBackground from "../../../layouts/GradientBackground";
import rdaLightTheme from "../../../themes/rdaLightTheme";

type PricingItemProps = {
  title: string;
  description?: string;
  price: string;
  billingMethod: string;
  priceDescription?: string;
  currency: string;
  features: string[];
  actionButton?: React.ReactNode;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
};

export default function PricingItem({
  title,
  description,
  currency,
  price,
  billingMethod,
  priceDescription,
  features,
  actionButton,
  children,
  sx = []
}: PricingItemProps) {
  return (
    <ThemeProvider theme={rdaLightTheme}>
      <Paper
        sx={[
          {
            overflow: "hidden"
          },
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
      >
        <GradientBackground
          sx={{
            p: 3,
            flexDirection: "column"
          }}
        >
          <Typography
            sx={(theme) => ({
              fontSize: "2.2rem",
              color: theme.palette.common.white
            })}
          >
            {title}
          </Typography>
          {description && (
            <Typography
              sx={(theme) => ({
                fontSize: "1rem",
                color: theme.palette.common.white
              })}
            >
              {description}
            </Typography>
          )}
        </GradientBackground>
        <Box
          sx={(theme) => ({
            p: 3,
            backgroundColor: theme.palette.grey["200"]
          })}
        >
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography sx={{ fontSize: "2rem", fontWeight: "700" }}>
              {getAmountInCurrency(currency, price)}
            </Typography>
            <Typography>{billingMethod}</Typography>
          </Box>
          <Typography>{priceDescription}</Typography>
        </Box>
        <Box sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
          {features.map((f, i) => (
            <Box
              key={`feature-${i}`}
              sx={{ display: "flex", gap: 1, alignItems: "center" }}
            >
              <DoneIcon sx={{ fontSize: "1rem" }} color="success" />
              {f}
            </Box>
          ))}
          {actionButton}
        </Box>
        {children}
      </Paper>
    </ThemeProvider>
  );
}
