import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DoneIcon from "@mui/icons-material/Done";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  Paper,
  PaperProps,
  Typography,
  styled
} from "@mui/material";
import React from "react";

type AdditionalNoteFormProps = {
  note: string;
  onAddNote: (n: string) => void;
  onToggleForm: (t: boolean) => void;
};

export default function AdditionalNoteForm({
  note,
  onAddNote,
  onToggleForm
}: AdditionalNoteFormProps) {
  const [localNote, setLocalNote] = React.useState<string>("");
  const [showForm, setShowForm] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLocalNote(note);
  }, [note]);

  const removeNote = () => {
    setLocalNote("");
    onAddNote("");
    setShowForm(false);
    onToggleForm(false);
  };

  const addNote = React.useCallback(() => {
    onAddNote(localNote);
    setShowForm(false);
    onToggleForm(false);
  }, [localNote]);

  const editNote = () => {
    setShowForm(true);
    onToggleForm(true);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      {localNote && !showForm && (
        <WrappingPaper variant="outlined">
          <IconButton
            onClick={() => removeNote()}
            color="error"
            sx={{ p: 1, ml: 1 }}
            aria-label="remove note"
          >
            <DeleteRoundedIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <Box sx={{ p: 2, flex: 1 }} onClick={() => editNote()}>
            <Typography>{localNote}</Typography>
          </Box>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            onClick={() => editNote()}
            color="secondary"
            sx={{ p: 1, mr: 1 }}
            aria-label="edit note"
          >
            <EditRoundedIcon />
          </IconButton>
        </WrappingPaper>
      )}
      {showForm && (
        <WrappingPaper variant="outlined">
          <IconButton
            onClick={() => removeNote()}
            color="error"
            sx={{ p: 1, ml: 1 }}
            aria-label="remove note"
          >
            <CloseRoundedIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <InputBase
            autoFocus
            sx={{ flex: 1, p: 1.5, ml: 0.5 }}
            placeholder="Add additional note to this invoice."
            inputProps={{ "aria-label": "additional note for invoice" }}
            value={localNote}
            onChange={(e) => setLocalNote(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addNote();
              }
            }}
            onBlur={() => {
              // On clicking outside of input, add Note
              addNote();
            }}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            color="success"
            sx={{ p: 1, mr: 1 }}
            aria-label="add note"
            onClick={() => addNote()}
          >
            <DoneIcon />
          </IconButton>
        </WrappingPaper>
      )}
      {!localNote && !showForm && (
        <Paper
          variant="outlined"
          sx={(theme) => ({
            flex: 1,
            p: 1.2,
            borderStyle: "dashed",
            backgroundColor: theme.palette.action.hover
          })}
        >
          <Button
            variant="text"
            fullWidth={true}
            size="small"
            endIcon={<AddRoundedIcon />}
            onClick={() => {
              setShowForm(true);
              onToggleForm(true);
            }}
            color="secondary"
          >
            Additional Note
          </Button>
        </Paper>
      )}
    </Box>
  );
}

const WrappingPaper = styled(Paper)<PaperProps>({
  flex: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center"
});
