import firebase from "firebase/compat/app";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import LayoutTemplate from "../components/templates/LayoutTemplate";
import useBusinesses from "../hooks/useBusinesses";
import { signIn, signOut } from "../redux/AuthSlice";
import { unload } from "../redux/BusinessSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { toggle } from "../redux/ThemeSlice";

export default function RootScreen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const { isLoading, businesses, reload, selectBusiness } = useBusinesses();
  const themeMode = useAppSelector((state) => state.theme.mode);

  const handleLogout = React.useCallback(async () => {
    await firebase.auth().signOut();
    dispatch(signOut());
    dispatch(unload());
    return navigate("/signin");
  }, [dispatch, signOut, unload, navigate]);

  const handleLogin = React.useCallback(async () => {
    return navigate("/signin");
  }, [navigate]);

  const handleToggleMode = React.useCallback(() => {
    dispatch(toggle());
  }, [dispatch, toggle]);

  // Check if user is logged in
  React.useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((firebaseUser) => {
        if (firebaseUser && !user) {
          dispatch(signIn(firebaseUser));
        }
      });

    // Make sure we un-register Firebase observers when the component unmounts.
    return () => unregisterAuthObserver();
  }, [dispatch, signIn]);

  return (
    <LayoutTemplate
      appBar={true}
      themeMode={themeMode}
      user={user}
      selectedBusiness={selectedBusiness}
      isLoading={isLoading}
      businesses={businesses}
      selectBusiness={selectBusiness}
      reloadBusinesses={reload}
      onLogin={handleLogin}
      onLogout={handleLogout}
      onToggleMode={handleToggleMode}
    >
      <Outlet />
    </LayoutTemplate>
  );
}
