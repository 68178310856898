import { PaletteMode } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ThemeState {
  mode: PaletteMode;
}

const initialThemeState: ThemeState = {
  mode: "light" // Default
};

export const themeSlice = createSlice({
  name: "theme",
  initialState: initialThemeState,
  reducers: {
    setMode: (state, action: PayloadAction<PaletteMode>) => {
      state.mode = action.payload;
    },
    toggle: (state) => {
      state.mode = state.mode === "dark" ? "light" : "dark";
    }
  }
});

export const { toggle, setMode } = themeSlice.actions;

export default themeSlice.reducer;
