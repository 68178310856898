import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Button, ButtonGroup } from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InvoicePageTemplate from "../components/templates/InvoicePageTemplate";
import useSingleInvoice from "../hooks/useSingleInvoice";
import { useAppSelector } from "../redux/hooks";

const BACKABLE_PATHS = ["/journals"];

export default function InvoiceSingleScreen() {
  const { invoiceId } = useParams();
  const { isLoading, invoice, reload } = useSingleInvoice();
  const selectedBusiness = useAppSelector((state) => state.business.business);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (invoiceId && selectedBusiness) {
      reload(invoiceId); // Get invoice
    }
  }, [invoiceId, reload, selectedBusiness]);

  const handleNavigateBack = React.useCallback(() => {
    if (location?.state?.from && BACKABLE_PATHS.includes(location.state.from)) {
      return navigate(-1);
    }
    return navigate("/invoices");
  }, [location]);

  return (
    <InvoicePageTemplate
      title="Invoice"
      isLoading={isLoading}
      isEmpty={!isLoading && !invoice}
      invoice={invoice}
      onClickBack={handleNavigateBack}
      endHeaderAction={
        invoice && (
          <ButtonGroup>
            <Button
              variant="contained"
              color="primary"
              aria-label="Print Preview"
              onClick={() => navigate(`/invoices/${invoice.uuid}/print`)}
            >
              Print Preview
            </Button>
            {invoice.invoice.status !== "PAID" && (
              <Button
                startIcon={<AddRoundedIcon />}
                aria-label="Add a new payment"
                onClick={() => navigate(`/invoices/${invoiceId}/payment`)}
                variant="contained"
                color="secondary"
                sx={{ whiteSpace: "nowrap" }}
              >
                Add Payment
              </Button>
            )}
          </ButtonGroup>
        )
      }
      isEmptyAction={
        <Button
          startIcon={<AddRoundedIcon />}
          aria-label="Add a new invoice"
          onClick={() => navigate("/invoices/create")}
          variant="contained"
          color="secondary"
          sx={{ whiteSpace: "nowrap" }}
        >
          Add Invoice
        </Button>
      }
    />
  );
}
