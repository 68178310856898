import { Button, ButtonProps, styled } from "@mui/material";
import { LinkProps } from "react-router-dom";

// Create a Gradient Button with LinkProps
const GradientButton = styled(Button)<ButtonProps & LinkProps>(({ theme }) => ({
  lineHeight: "initial", // To remove the weird text alignment issue
  padding: theme.spacing(0.8),
  paddingRight: theme.spacing(1.5),
  paddingLeft: theme.spacing(1.5),
  textAlign: "center",
  color: "#fff",
  background: "linear-gradient(to right, #997c6c, #414645)",
  "&.Mui-disabled": {
    background: "linear-gradient(to right, #997c6c66, #41464566)"
  },
  "&:hover": {
    opacity: 0.8
  }
}));

export default GradientButton;
