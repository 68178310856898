import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import {
  Box,
  Paper,
  PaperProps,
  styled,
  TextField,
  Typography
} from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import React from "react";
import { IBusiness } from "../../../types/IBusiness";
import { ICompany } from "../../../types/ICompany";
import { IInvoiceTemplate } from "../../../types/IInvoiceTemplate";
import CurrencySelector from "../../atoms/CurrencySelector";
import CompanyEditor from "../CompanyEditor";
import InvoiceTemplateEditor from "../InvoiceTemplateEditor";

type BusinessEditorProps = {
  business: IBusiness | null;
  setBusiness: (bs: IBusiness | null) => void;
};

export default function BusinessEditor({
  business,
  setBusiness
}: BusinessEditorProps) {
  const [logo, setLogo] = React.useState<string>("");
  const [abbreviation, setAbbreviation] = React.useState<string>("");
  const [currency, setCurrency] = React.useState<string | null>("SGD");
  const [company, setCompany] = React.useState<ICompany | null>(null);
  const [logoFile, setLogoFile] = React.useState<File | undefined>(undefined);
  const [logoFileError, setLogoFileError] = React.useState<string>("");
  const [templateId, setTemplateId] = React.useState<string>("");
  const [footer, setFooter] = React.useState<string>("");
  const [footnotes, setFootnotes] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (business) {
      if (business.logo) {
        setLogo(business.logo);
      }
      if (business.abbreviation) {
        setAbbreviation(business.abbreviation);
      }
      if (business.currency) {
        setCurrency(business.currency);
      }
      if (business.company) {
        setCompany(business.company);
      }
      if (business.template) {
        // Support only 1 template per business
        setTemplateId(business.template.uuid ?? "");
        setFooter(business.template.footer ?? "");
        setFootnotes(business.template.footnote ?? []);
      }
    }
  }, [business]);

  React.useEffect(() => {
    if (!company || !currency) {
      return;
    }

    const template: IInvoiceTemplate = {
      uuid: templateId,
      footer: footer,
      footnote: footnotes
    };

    setBusiness({
      uuid: business?.uuid,
      abbreviation,
      currency,
      company: company,
      logo,
      template: template
    });
  }, [
    abbreviation,
    business,
    currency,
    company,
    logo,
    footer,
    footnotes,
    templateId
  ]);

  const handleChangeLogoFile = React.useCallback((newFile: File | null) => {
    setLogoFileError("");
    setLogoFile(newFile ? newFile : undefined);
    if (newFile) {
      if (newFile.size > 500000) {
        // Exceeded file size 500kb
        setLogoFileError("File size exceeded 500kb.");
        setLogo("");
        return;
      }
      const filereader = new FileReader();
      filereader.readAsDataURL(newFile);
      filereader.onload = function (evt) {
        if (evt.target) {
          const base64 = evt.target.result;
          if (base64) {
            setLogo(base64.toString());
          }
        }
      };
    }
  }, []);

  return (
    <ContainerPaper variant="outlined">
      <SectionPaper
        variant="outlined"
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <MuiFileInput
          fullWidth
          id="inputLogo"
          label="Upload business logo"
          InputLabelProps={{
            htmlFor: "inputLogo-fieldinput"
          }}
          placeholder="Upload Business Logo"
          value={logoFile}
          onChange={handleChangeLogoFile}
          error={!!logoFileError}
          helperText={
            logoFileError
              ? logoFileError
              : "Logo is used in your invoice header."
          }
          inputProps={{
            accept: ".png, .jpeg"
          }}
          InputProps={{
            startAdornment: (
              // Workaround to eliminate Label warning
              <input id="inputLogo-fieldinput" style={{ display: "none" }} />
            ),
            endAdornment: <FileUploadRoundedIcon />
          }}
        />
        {logo && logo.startsWith("data:image") && (
          <Box sx={{ maxWidth: "100%" }}>
            <Typography>Uploaded Logo</Typography>
            <Paper variant="outlined" sx={{ borderStyle: "dotted" }}>
              <img
                src={logo}
                alt="Business Logo"
                height="auto"
                width="auto"
                style={{ maxHeight: "150px", maxWidth: "300px" }}
              />
            </Paper>
          </Box>
        )}
      </SectionPaper>
      <SectionPaper variant="outlined">
        <CompanyEditor company={company} setCompany={setCompany} />
      </SectionPaper>
      <SectionPaper
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2
        }}
      >
        <TextField
          fullWidth
          size="small"
          id="inputAbbreviation"
          label="Abbreviation"
          value={abbreviation}
          onChange={(e) => setAbbreviation(e.target.value)}
          placeholder="ABC"
          helperText="Abbreviation is appended to your invoice number. E.g ABC000001."
        />
        <CurrencySelector
          selectedCode={currency}
          onChange={(newCurrency) => setCurrency(newCurrency)}
          size="small"
          fullWidth
          helperText="Currency is used to differentiate invoices in foreign currencies."
        />
      </SectionPaper>
      <SectionPaper variant="outlined">
        <InvoiceTemplateEditor
          footer={footer}
          footnotes={footnotes}
          setFooter={setFooter}
          setFootnotes={setFootnotes}
        />
      </SectionPaper>
    </ContainerPaper>
  );
}

const ContainerPaper = styled(Paper)<PaperProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: 0,
    borderWidth: 0,
    backgroundColor: "transparent"
  }
}));

const SectionPaper = styled(Paper)<PaperProps>(({ theme }) => ({
  borderStyle: "dashed",
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2)
  }
}));
