import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {
  Alert,
  Box,
  BoxProps,
  Button,
  Grid,
  ThemeProvider,
  Typography,
  styled
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useParams } from "react-router-dom";
import LoadingBox from "../components/atoms/LoadingBox";
import CompanyPrintView from "../components/CompanyPrintView";
import InvoiceInfoPrintView from "../components/InvoiceInfoPrintView";
import JournalsPrintView from "../components/JournalsPrintView";
import PaymentsPrintView from "../components/PaymentsPrintView";
import usePrintInvoice from "../hooks/usePrintInvoice";
import { PageTitleTypography } from "../styledMuiComponents";
import rdaLightTheme from "../themes/rdaLightTheme";

export default function InvoicePublicPrintScreen() {
  const { invoiceId, invoiceNumber } = useParams();
  const { isLoading, invoice, reload, error } = usePrintInvoice();
  const [initialising, setInitialising] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (invoiceId && invoiceNumber) {
      reload(invoiceId, invoiceNumber).then(() => setInitialising(false)); // Get invoice
    }
  }, [invoiceId, invoiceNumber, reload]);

  React.useEffect(() => {
    if (invoice) {
      // Change Page Title so that print will take it as filename.
      const invoiceDate = dayjs(invoice.invoice.invoice_date);
      const title = [
        invoice.business.company.name,
        invoice.bill_to.company.name,
        invoice.invoice.number,
        invoiceDate.format("YYYY-MM-DD")
      ];
      document.title = title.join("-").replace(/ /g, "-");
    }
  }, [invoice]);

  return (
    <ThemeProvider theme={rdaLightTheme}>
      <PrintLayoutContainer id="rda-screen-invoice-print">
        <NavBox>
          <PageTitleTypography
            variant="h1"
            sx={(theme) => ({ marginBottom: 0, marginLeft: theme.spacing(3) })}
          >
            Invoice
          </PageTitleTypography>
          <Box sx={{ flex: 1 }} />
          <Button
            startIcon={<AddRoundedIcon />}
            aria-label="Add a new payment"
            variant="contained"
            color="secondary"
            onClick={() => window.print()}
          >
            Print
          </Button>
        </NavBox>
        {initialising && <LoadingBox message="loading invoice..." />}
        {!initialising && !isLoading && invoice && (
          <InvoiceContainer>
            <Grid container spacing={2}>
              {invoice.bill_to && (
                <Grid item xs={6}>
                  {invoice.business.logo &&
                    invoice.business.logo.startsWith("data:image") && (
                      <LogoContainer>
                        <img
                          src={invoice.business.logo}
                          alt="Business Logo"
                          height="auto"
                          width="auto"
                          style={{ maxHeight: "200px", maxWidth: "80%" }}
                        />
                      </LogoContainer>
                    )}
                  <BilltoContainer>
                    <Typography variant="h3" sx={{ mb: 1 }}>
                      Bill to
                    </Typography>
                    {invoice.bill_to.attention_to && (
                      <Typography sx={{ mb: 2 }}>
                        Attention to: {invoice.bill_to.attention_to}
                      </Typography>
                    )}
                    <CompanyPrintView company={invoice.bill_to.company} />
                  </BilltoContainer>
                </Grid>
              )}
              {invoice.invoice && (
                <Grid item xs={6}>
                  <InvoiceInfoContainer>
                    <Typography variant="h1" sx={{ mb: 2 }}>
                      INVOICE
                    </Typography>
                    <CompanyPrintView
                      company={invoice.business.company}
                      sx={{ mb: 2 }}
                    />
                    <InvoiceInfoPrintView invoiceInfo={invoice.invoice} />
                  </InvoiceInfoContainer>
                </Grid>
              )}
            </Grid>
            {invoice.journals && (
              <Grid item xs={12}>
                <JournalsPrintView
                  journals={invoice.journals}
                  currency={invoice.invoice.currency}
                  taxes={invoice.taxes}
                  total_tax={
                    invoice.taxes?.length > 0 ? invoice.total_tax : undefined
                  }
                  subtotal={invoice.subtotal}
                  grand_total={invoice.grand_total}
                />
              </Grid>
            )}
            {invoice.payments && invoice.payments.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h6">
                  Payment{invoice.payments.length > 1 ? "s" : ""}
                </Typography>
                <PaymentsPrintView payments={invoice.payments} />
              </Grid>
            )}
            {invoice?.additional_note && (
              <Grid item xs={12} className="avoid-break">
                <Typography variant="h4" sx={{ mt: 2 }}>
                  Additional Note:
                </Typography>
                <Typography>{invoice.additional_note}</Typography>
              </Grid>
            )}
            {invoice?.template?.footnote &&
              invoice.template.footnote.length > 0 && (
                <Grid item xs={12} className="avoid-break">
                  <Typography variant="h4" sx={{ mt: 2 }}>
                    Notes:
                  </Typography>
                  {invoice.template.footnote.map((note, index) => (
                    <Typography key={`footnote-${index}`}>{note}</Typography>
                  ))}
                </Grid>
              )}
            {invoice?.template?.footer && (
              <FooterContainer>
                <Typography>{invoice.template.footer}</Typography>
              </FooterContainer>
            )}
          </InvoiceContainer>
        )}
        {!initialising && !isLoading && !invoice && (
          <Alert
            severity="error"
            elevation={0}
            sx={(theme) => ({ padding: theme.spacing(3) })}
          >
            <Typography>Invoice not found.</Typography>
            {error && <Typography>{error}</Typography>}
          </Alert>
        )}
      </PrintLayoutContainer>
    </ThemeProvider>
  );
}

const PrintLayoutContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "block",
  overflow: "visible",
  backgroundColor: theme.palette.common.white,
  "& h1": {
    fontSize: "40px",
    color: `${theme.palette.text.primary} !important`
  },
  "& h3": {
    fontSize: "22px",
    fontWeight: "bold",
    color: `${theme.palette.text.primary} !important`
  },
  "& h4": {
    fontSize: "16px",
    fontWeight: "bold",
    color: `${theme.palette.text.primary} !important`
  },
  "& p": {
    fontSize: "14px",
    color: `${theme.palette.text.primary} !important`
  },
  "@media screen": {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: theme.spacing(3)
  },
  "& .avoid-break": {
    pageBreakInside: "avoid",
    pageBreakAfter: "auto"
  }
}));

const NavBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  "@media print": {
    display: "none"
  }
}));

const InvoiceContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  rowGap: 2,
  backgroundColor: theme.palette.common.white,
  "@media screen": {
    padding: theme.spacing(3)
  }
}));

const LogoContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(4)
}));

const BilltoContainer = styled(Box)<BoxProps>(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

const InvoiceInfoContainer = styled(Box)<BoxProps>(({ theme }) => ({
  textAlign: "right",
  marginBottom: theme.spacing(4)
}));

const FooterContainer = styled(Box)<BoxProps>(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  display: "block",
  width: "100%",
  height: "16px",
  textAlign: "center",
  pageBreakInside: "avoid",
  pageBreakAfter: "auto",
  "& p": {
    fontSize: 10
  },
  "@media screen": {
    position: "relative",
    marginTop: theme.spacing(4)
  }
}));
